// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/builds/beckerzietz/bz-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("/builds/beckerzietz/bz-website/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/builds/beckerzietz/bz-website/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/builds/beckerzietz/bz-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-philosophie-js": () => import("/builds/beckerzietz/bz-website/src/pages/philosophie.js" /* webpackChunkName: "component---src-pages-philosophie-js" */),
  "component---src-pages-referenzen-js": () => import("/builds/beckerzietz/bz-website/src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-tech-js": () => import("/builds/beckerzietz/bz-website/src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */)
}

