module.exports = [{
      plugin: require('/builds/beckerzietz/bz-website/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/beckerzietz/bz-website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#2d3748","theme_color":"#2d3748","display":"minimal-ui","icon":"src/images/logo.png"},
    },{
      plugin: require('/builds/beckerzietz/bz-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
